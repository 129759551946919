import React, { useContext } from "react";
import {
  StyledFounders,
  StyledFoundersContent,
  StyledFoundersCTA,
  StyledFoundersImage,
  StyledFoundersParagraph,
} from "./style.jsx";
import Button from "../Button/Button";
import Icon, { icons } from "../Icon/Icon";
import { StyledGridRow } from "../../commons/Grid";
import { DataContext } from "../../contexts/DataContext";

export default ({ image, paragraphs, cta }) => {
  const { i18nLink } = useContext(DataContext);

  return (
    <StyledGridRow>
      <StyledFounders>
        <StyledFoundersImage src={image} loading="lazy" />
        <StyledFoundersContent>
          {paragraphs.map((paragraph, index) => (
            <StyledFoundersParagraph
              key={index}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          ))}
          <StyledFoundersCTA>
            <Button as="div" noPadding theme="white" to={cta.url ? i18nLink(`${cta.url}`) : null}>
              {cta.label} <Icon icon={icons.arrowRight} />
            </Button>
          </StyledFoundersCTA>
        </StyledFoundersContent>
      </StyledFounders>
    </StyledGridRow>
  );
};
